import { useMemo, useRef, useState } from 'react'
import Loader from "assets/images/svg/Loader.svg"
import { toast } from 'react-toastify';
import { useTheme } from 'context/useAppTheme';

interface Props {
    setModal: (val: boolean) => void
}

function WaitlistForm(props: Props) {
    const { setModal } = props;
    const { isLight } = useTheme();
    const nameInputRef = useRef<any>()
    const labeInputRef = useRef<any>()
    const emailInputRef = useRef<any>()
    const emailLabeInputRef = useRef<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [payload, setPayload] = useState<{ email?: any, name?: any, message?: any }>({
        name: "",
        email: "",
        message: ""
    })

    const saveInfo = (val: { email?: string, name?: string, message?: string }) => {
        setPayload(prev => ({ ...prev, ...val }));
    };


    const sendForm = async () => {
        setLoading(true)
        if (isDisabled) return
        toast.clearWaitingQueue()
        try {
            await fetch('https://dummyjson.com/posts/add', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: 'simple test datas',
                    userId: 5,
                    ...payload
                })
            })
                .then(res => res.json())
                .then(console.log);
            toast.success('Joined Successfully.', {
                position: "top-center",
                autoClose: 5000,
                toastId: 1,
                pauseOnHover: true,
                theme: isLight ? "light" : 'dark',
            });
            setPayload({ name: '', email: "", message: "" })
            setModal(false)

        } catch (error: any) {
            toast.error("Error Occurred ", {
                position: "top-center",
                autoClose: 5000,
                // hideProgressBar: false,
                pauseOnHover: true,
                theme: isLight ? "light" : 'dark',
            });
        } finally {
            setLoading(false)
        }
    }

    const isDisabled = useMemo(() => {
        return payload.email?.trim() === "" || payload.name?.trim() === "" || payload.message?.trim() === "" || loading;
    }, [payload, loading]);

    return (
        <form className='form_wrapper'
            onSubmit={(e) => {
                e.preventDefault();
                sendForm()
            }}
        >
            {/* night_primary_blue: "rgb(93, 30, 196)", */}

            <h3 className={`font-semibold text-[1.1rem] ${'text-white'}`}>
                Join the Waitlist for VIP Service Done Right!"
            </h3>
            <p className={`my-4 ${'text-night_greg/70'}`}>Please fill the form</p>
            <div className=''>
                {/* <label className={`${'text-night_greg/70'}`}>Business name</label> */}
                {/* <input
                    value={payload.name}
                    required
                    onChange={(e: any) => {
                        saveInfo({ name: e.target?.value })
                    }}
                    className={`${'text-white dark_input'}`} placeholder='John Doe' /> */}
                <div className="col-3 input-effect relative club_name">
                    <input
                        onChange={(e: any) => {
                            saveInfo({ name: e.target?.value })
                        }}
                        onFocus={()=>{
                            nameInputRef?.current.classList.add('focus_effect')
                            labeInputRef?.current.classList.add('focus_label')
                        }}
                        onBlur={()=>{
                            if(!payload.name){
                                nameInputRef?.current.classList.remove('focus_effect')
                                labeInputRef?.current.classList.remove('focus_label')
                            }
                        }}
                        ref={nameInputRef}
                        required
                        value={payload.name}
                        className="effect-16" type="text" />
                    <label  ref={labeInputRef}>Business name</label>
                </div>
            </div>
            <div>
                {/* <input
                    value={payload.email}
                    type='email'
                    required
                    onChange={(e: any) => {
                        saveInfo({ email: e.target?.value })
                    }}
                    className={`${'text-white dark_input'}`} placeholder='Email' /> */}

                <div className="col-3 input-effect relative club_name">
                    <input
                        onChange={(e: any) => {
                            saveInfo({ email: e.target?.value })
                        }}
                        onFocus={()=>{
                            emailInputRef?.current.classList.add('focus_effect')
                            emailLabeInputRef?.current.classList.add('focus_label')
                        }}
                        onBlur={()=>{
                            if(!payload.email){
                                emailInputRef?.current.classList.remove('focus_effect')
                                emailLabeInputRef?.current.classList.remove('focus_label')
                            }
                        }}
                        required
                        ref={emailInputRef}
                        value={payload.email}
                        type='email'
                        className="effect-16"  />
                    <label   ref={emailLabeInputRef}>Email</label>
                </div>
            </div>
            <div className='club_message'>
                <label className={`${'text-night_greg/70'}`}>
                    What do you think we can add to make your experience more seamless</label>
                <textarea
                    required
                    value={payload.message}
                    onChange={(e: any) => {
                        saveInfo({ message: e.target?.value })
                    }}
                    className={`${'dark_input'}`} placeholder='Message'></textarea>
            </div>
            <div>
                <button
                    disabled={isDisabled}
                    className={`mt-0  rounded-2xl text-center py-4 text-white w-full flex justify-center ${isDisabled ? 'bg-gray-400/80 cursor-not-allowed' : 'bg-night_primary_blue cursor-pointer'
                        }`}>
                    {!loading && 'Submit'}
                    {loading && <img alt='loader img' src={Loader} className='roller w-6' />}
                </button>
            </div>
        </form>
    )
}

export default WaitlistForm
