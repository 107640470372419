

interface Props {
    scrollValue: number | string
}

function SPiralRound(props: Props) {
    const { scrollValue } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="208" height="221" viewBox="0 0 208 221" fill="none" >
            <path d="M4.00031 4C27.0283 60.7645 89.6153 162.593 162.94 128.976C213.003 106.025 174.738 55.4539 121.003 82.5566C67.2683 109.659 83.0463 206.566 201.987 197.197M201.987 197.197L186.02 180.208M201.987 197.197L177.315 216.567"
            stroke="#F99D16" 
            stroke-width="8" 
            stroke-linecap="round" 
            stroke-dasharray="1000" 
            stroke-dashoffset={ `${1000 * ((Number(scrollValue) * 2.6)-1)}` }/>
        </svg>
     )
}

export default SPiralRound