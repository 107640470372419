import React, { useRef, useState } from "react";
import Applogo from '../../../../assets/icons/Applogo.svg';
import ApplogoLight from "../../../../assets/icons/AppLogoLight.svg"
import { useTheme } from "context/useAppTheme";
import ToggleMode from "components/widget/ToggleMode";

interface Props {
    moveToSections: Function,
    navOpacity: number
}


const TopNav: React.FC<Props> = (props: Props) => {
    const [dropDown, setDropDown] = useState<boolean>(true)
    const { moveToSections, navOpacity } = props
    const { isLight } = useTheme()
    const hamburger = useRef<HTMLDivElement | null>(null)

    const setNavAction = () => {
        if (dropDown) {
            hamburger.current?.classList.add('is-active');
            setDropDown(!dropDown);
        } else {
            hamburger.current?.classList.remove('is-active');
            setDropDown(!dropDown);
        }
        //    setDropDown(!dropDown)
    }

    const setMoveSection = (val: string) => {
        moveToSections(val)
        setDropDown(!dropDown);
        hamburger.current?.classList.remove('is-active');
    }

    return (
        <div className="px-3 pt-3 lg:px-10 xl:px-20 fixed top-0 left-0 p-4 w-full z-[100] transition duration-300 backdrop-blur-lg"
            style={{ backgroundColor: `${isLight ? 'rgba(234, 232, 254,' : 'rgba(17, 13, 33,'}${navOpacity < 0.03 && dropDown ? 0 : 0.7})` }}
        >
            <div className="nav_top_container lg:py-1">
                <div className="logo_container">
                    <img alt="" src={isLight ? ApplogoLight : Applogo} />
                    <button
                        onClick={() => setNavAction()}
                    >
                        <div className="three col">
                            <div className="hamburger" ref={hamburger} id="hamburger-6">
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                            </div>
                        </div>
                    </button>
                </div>
                <div className={`responsive_nav scrollable-hide-scrollbar  ${dropDown ? 'max_h_0' : 'max_h_screen'}`}>
                    <div className="links_container pt-6 lg:pt-0 w-full">
                        <div className="flex flex-wrap w-full lg:justify-center items-center lg:w-7/12 nav_link_wrapper lg:flex-1">
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'} lg:mx-4`}
                                onClick={() => setMoveSection('hero')}
                            >
                                <span className="text_place">Home</span>
                                <b className="text_replace">Home</b>
                            </div>
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'} lg:mx-4`}
                                onClick={() => setMoveSection('howitworks')}
                            >
                                <span className="text_place">How it works</span>
                                <b className="text_replace">How it works</b>
                            </div>
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'} lg:mx-4`}
                                onClick={() => setMoveSection('contact_us')}
                            >
                                <span className="text_place">Contact</span>
                                <b className="text_replace">Contact</b>
                            </div>
                        </div>
                        <div className="flex items-center flex-wrap mt-24 lg:mt-0">
                            <span className="inline-flex w-full lg:w-auto  justify-end my-3 lg:my-0 lg:mx-5">
                                <ToggleMode />
                            </span>
                            <div
                                className={` cursor-pointer px-5 text-sm ${isLight ? 'sign_up_btn draw_border_light' : 'sign_up_btn draw_border'}`}>
                                Download
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopNav;
