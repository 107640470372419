import React, { useEffect } from "react";

interface Props { }


const Test: React.FC<Props> = (props: Props) => {

    useEffect(()=>{
      
     },[])

    return (
        <div className="px-3 pt-3 relative flex justify-center"
        >
    
        </div>
    );
};

export default Test;
