import React, { useEffect, useRef } from "react";
import { useTheme } from "context/useAppTheme";
import CusSearch from "../../../../assets/images/svg/CusSearch.svg"
import CusBookSvg from "../../../../assets/images/svg/CusBookSvg.svg"
import CusOrder from "../../../../assets/images/svg/CusOrder.svg"
import CusQrcode from "../../../../assets/images/svg/CusQrcode.svg"
import PartyNyt from "../../../../assets/images/svg/Party_nyt.svg";
import PanaImage from "../../../../assets/images/pana.png"
import BookTable from "../../../../assets/images/BookTable.svg"
import PreOrder from "../../../../assets/images/PreOrder.svg"
import QRCideImage from "../../../../assets/images/QRCideImage.svg"
// import Poster from "../../../../assets/images/Poster.png"
import MaskImage from "../../../../assets/images/Mask_group.svg"
import { useInView } from "react-intersection-observer";
import SPiralRound from "assets/images/svg/SPiralRound";
// import { gsap } from 'gsap';
// import { ScrollTrigger } from "gsap/ScrollTrigger";


interface Props { }


const HowItWorks: React.FC<Props> = (props: Props) => {
    const { isLight, scrollPercent } = useTheme()
    const pinnerRef = useRef<any>()

    const { inView } = useInView({
        triggerOnce: false, // Make sure the animation can trigger multiple times
        rootMargin: '-50% 0px -60% 0px'
    });
    // const { ref: ref_2, inView: inView_2 } = useInView({
    //     triggerOnce: false, // Make sure the animation can trigger multiple times
    //     rootMargin: '-50% 0px -60% 0px'
    // });
    // const { ref: ref_3, inView: inView_3 } = useInView({
    //     triggerOnce: false, // Make sure the animation can trigger multiple times
    //     rootMargin: '-50% 0px -60% 0px'
    // });
    // const { ref: ref_4, inView: inView_4 } = useInView({
    //     triggerOnce: false, // Make sure the animation can trigger multiple times
    //     rootMargin: '-50% 0px -60% 0px'
    // });
    // const { ref: ref_5, inView: inView_5 } = useInView({
    //     triggerOnce: false, // Make sure the animation can trigger multiple times
    //     rootMargin: '-50% 0px -60% 0px'
    // });

    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger);
        // const textTwo = pinnerRef?.current;
        // gsap.to(textTwo, {
        //     scrollTrigger: {
        //         trigger: "#sectionTwo",
        //         pin: true,
        //         scrub: true,
        //         start: "top top",
        //         end: "+=700px",
        //         markers: true
        //     }
        // });
        //   gsap.registerPlugin(ScrollTrigger)
        //     gsap.to('.logo', {
        //      duration: 3,
        //      scrollTrigger: {
        //          trigger: '.logos',
        //          start: "top top",
        //          end: "+=700px",
        //          markers: true,
        //          scrub: 1,
        //          pin: true,
        //          toggleActions: 'restart none none none',
        //          onEnter: () => console.log("Element .c has been reached!"),
        //      },
        //     ease: 'none'
        //     })
    }, [])

    return (
        <div className="connect_hero_container w-full pinner logos">

            <div className="flex justify-center mt-0 ">
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 relative">
                    <p className={`mb-8 text-[24px] w-full mt-16 ${isLight ? 'text-black' : 'text-white'}`}>How it works</p>
                    <div ref={pinnerRef} className="flex flex-wrap w-full pinner items-center justify-between">
                        <div className="w-full lg:w-6/12 mb-8">
                            <div className="absolute top-[150px] left-[-150px] hidden md:block">
                                <SPiralRound scrollValue={scrollPercent} />
                            </div>
                            <div className="w-full">
                                <img alt="howitworks-img" src={MaskImage} className="w-full md:w-[calc(90%-1rem)]" />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 mb-8 lg:px-10 relative" id="#sectionTwo">
                            <div className={`flex flex-wrap items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-10" src={CusSearch} />
                                </div>

                                <div className="flex-1">
                                    <b className="text-[24px] font-[500]">Search for a Table</b>
                                </div>
                                <p className="text-[18px] w-full pl-2 pt-3">Filter by music type, location, pricing.</p>
                            </div>
                            <img alt="howitworks-img" src={BookTable} className="w-[calc(90%-1rem)] absolute md:top-[calc(50%+80px)] hidden md:block" />
                        </div>
                    </div>

                    <div ref={pinnerRef} className="flex flex-wrap w-full pinner items-center justify-between">
                        <div className="w-full lg:w-6/12 mb-8 lg:px-10 md:order-1 order-2" id="#sectionTwo">
                            <div className={`flex flex-wrap items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-14" src={CusBookSvg} />
                                </div>

                                <div className="flex-1">
                                    <b className="text-[24px] font-[500]">Book your Table</b>
                                </div>
                                <p className="text-[18px] w-full pl-2 pt-3">Get instant confirmation upon booking.</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 mb-8 md:order-2 order-1">
                            <div className="w-full lg:relative">
                                <img alt="howitworks-img" src={BookTable} className="w-[calc(100%-1rem)]  md:hidden block" />
                            </div>
                        </div>
                    </div>

                    <div ref={pinnerRef} className="flex flex-wrap w-full pinner items-center justify-between">
                        <div className="w-full lg:w-6/12 mb-8">
                            <div className="w-full">
                                <img alt="howitworks-img" src={PreOrder} className="w-full md:w-[calc(90%-1rem)]" />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 mb-8 lg:px-10 relative" id="#sectionTwo">
                            <div className={`flex flex-wrap items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-14" src={CusOrder} />
                                </div>

                                <div className="flex-1">
                                    <b className="text-[24px] font-[500]">Pre-order your drinks</b>
                                </div>
                                <p className="text-[18px] w-full pl-2 pt-3">View bottle pricing and pre-order to have it ready when you arrive.</p>
                            </div>
                            <img alt="howitworks-img" src={QRCideImage} className="w-full md:w-[calc(90%-1rem)] absolute md:top-[calc(50%+80px)]" />
                        </div>
                    </div>

                    <div ref={pinnerRef} className="flex md:mb-[10%] flex-wrap w-full pinner items-center justify-between">
                        <div className="w-full lg:w-6/12 mb-8 lg:px-10 md:order-1 order-2" id="#sectionTwo">
                            <div className={`flex flex-wrap items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-14" src={CusQrcode} />
                                </div>

                                <div className="flex-1">
                                    <b className="text-[24px] font-[500]">Get QR code to share with Friends</b>
                                </div>
                                <p className="text-[18px] w-full pl-2 pt-3">Share QR code tickets with table members for a seamless night.</p>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 mb-8 md:order-2 order-1">
                            <div className="w-full lg:relative">
                            <img alt="howitworks-img" src={QRCideImage} className=" w-full  md:hidden block" />
                            </div>
                        </div>
                    </div>

                    {/* <div  className={`flex items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView_2 ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-10" src={CusBookSvg} />
                                </div>
                                <div className="flex-1">
                                    <b className="text-[20px] font-[500]">Book your Table</b>
                                    <p className="text-[14px]">Get instant confirmation upon booking. </p>
                                </div>
                            </div>
                            <div className={`c flex items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView_3 ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-10" src={CusOrder} />
                                </div>
                                <div className="flex-1">
                                    <b className="text-[20px] font-[500]">Pre-order your drinks</b>
                                    <p className="text-[14px]">View bottle pricing and pre-order to have it ready when you arrive. </p>
                                </div>
                            </div>
                            <div className={`c flex items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView_4 ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-10" src={CusQrcode} />
                                </div>
                                <div className="flex-1">
                                    <b className="text-[20px] font-[500]">Get QR code to share with Friends</b>
                                    <p className="text-[14px]">Share QR code tickets with table members for a seamless night. </p>
                                </div>
                            </div>
                            <div className={`c flex items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView_5 ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-10" src={Cusparty} />
                                </div>
                                <div className="flex-1">
                                    <b className="text-[20px] font-[500]">Party the Night Away</b>
                                    <p className="text-[14px]">Get tipsy and forget the check, it's already been covered at booking!</p>
                                </div>
                            </div> */}


                    <div ref={pinnerRef} className="flex flex-wrap w-full pinner items-center justify-between mt-36">
                        <div className="w-full lg:w-6/12 mb-8">
                            <div className="w-full lg:relative">
                                <img alt="howitworks-img" src={PanaImage} className="w-[calc(100%-1rem)]" />
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 mb-8 lg:px-10" id="#sectionTwo">
                            <div className={`flex flex-wrap items-center w-full py-3 px-2 rounded-2xl mb-6 p_label
                                ${isLight ? 'text-black' : 'text-white'}
                                 ${inView ? 'draw_border_outline' : ''}`}>
                                <div className="px-2">
                                    <img alt="howitworks-img" className="mr-1 w-20" src={PartyNyt} />
                                </div>

                                <div className="flex-1">
                                    <b className="text-[24px] md:text-[27px] font-[500]">Party the Night Away</b>
                                </div>
                                <p className="text-base md:text-[20px] w-full pl-2 pt-3">Get tipsy and forget the check, it's already been covered at Night Owl!</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
