import React from "react";
import { useTheme } from "context/useAppTheme";
import EffortLess from "../../../assets/images/svg/effortlessSvg.svg";
import EnhanceSvg from "../../../assets/images/svg/enhancedSvg.svg";
import QrcodeSvg from "../../../assets/images/svg/QrcodeSvg.svg";
import flexibleSvg from "../../../assets/images/svg/flexibleSvg.svg";
import CentralizedSvg from "../../../assets/images/svg/CentralizedSvg.svg";
import Sidebubble from "../../../assets/images/ball_edge.png"
import FeatureArrow from "../../../assets/images/svg/FeatureArrow.svg"
import realTimeSvg from "../../../assets/images/svg/realTimeSvg.svg"
import inventorySvg from "../../../assets/images/svg/inventorySvg.svg"
import seamleassSvg from "../../../assets/images/svg/seamleassSvg.svg"
import { useInView } from "react-intersection-observer";
import { animated, useSpring } from "@react-spring/web";

interface Props { }


const Features: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()

    const ViewProperty = {
        triggerOnce: true, // Make sure the animation can trigger multiple times
        rootMargin: '-15% 0px -15% 0px',
        threshold: 0.1
    }

    const { ref, inView } = useInView(ViewProperty);
    const { ref: ref2 } = useInView(ViewProperty);
    const { ref: ref3 } = useInView(ViewProperty);
    const { ref: ref4 } = useInView(ViewProperty);
    const { ref: ref5 } = useInView(ViewProperty);
    const { ref: ref6 } = useInView(ViewProperty);
    const { ref: ref7 } = useInView(ViewProperty);
    const { ref: ref8 } = useInView(ViewProperty);

    const springProps = useSpring({
        from: { y: 200, opacity: 0 },
        to: { y: inView ? 0 : 200, opacity: inView ? 1 : 0, }, // Animate height based on `inView`
        config: { duration: 700 }
    });

    const springPropsDelay = useSpring({
        from: { y: -300, opacity: 0 },
        to: { y: inView ? 0 : -300, opacity: inView ? 1 : 0, }, // Animate height based on `inView`
        config: { duration: 700, delay: 300 }
    });

    const springPropsDelayMax = useSpring({
        from: { scale: 0, opacity: 0 },
        to: { scale: inView ? 1 : 2, opacity: inView ? 1 : 0, }, // Animate height based on `inView`
        config: { duration: 500, delay: 8000 }
    });

    return (
        <div className="connect_hero_container w-full" >
            <div className="flex justify-center mt-0 lg:pt-12 ">
                <img alt={'alt-text'} src={Sidebubble} className="absolute left-0 top-[30%] -z-[1]" />
                <div ref={ref} className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 relative">
                    <img alt={'alt-text'} src={FeatureArrow} className="absolute -left-[10%] -top-[25%] -z-[1] hidden lg:inline-block" />
                    <p className={`mb-4 text-[24px] ${isLight ? 'text-black' : 'text-white'}`}>Key features</p>
                    <div className="w-full flex flex-wrap justify-between">
                        <animated.div className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springProps}
                        >
                            <img alt={'alt-text'} src={EffortLess} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Effortless Reservation management</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Handle table reservations and ticket sales from one central platform.</p>
                        </animated.div>
                        <animated.div ref={ref2} className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springProps}
                        >
                            <img alt={'alt-text'} src={EnhanceSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Enhanced User experience</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Users get instant confirmation of their table bookings and can pre-order bottle service.</p>
                        </animated.div >
                        <animated.div ref={ref3} className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springProps}
                        >
                            <img alt={'alt-text'} src={seamleassSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Seamless support handling</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                        </animated.div>
                        <animated.div ref={ref4} className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springProps}
                        >
                            <img alt={'alt-text'} src={QrcodeSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>QR code entry</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>QR code ticketing for limited access to tables allowing easier entry at the door.</p>
                        </animated.div>
                        <animated.div ref={ref8} className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                         style={springPropsDelayMax}
                        >
                            <img alt={'alt-text'} src={flexibleSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Flexible pricing</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Adjustable pricing to accommodate high traffic weekends and increase profits.</p>
                        </animated.div>
                        <animated.div ref={ref5} className={`feature_segment ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springPropsDelay}
                        >
                            <img alt={'alt-text'} src={CentralizedSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Centralized payments</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                        </animated.div>
                        <animated.div ref={ref6} className={`feature_segment_semi ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            style={springPropsDelay}
                        >
                            <img alt={'alt-text'} src={realTimeSvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Monitor data in realtime</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>QR code ticketing for limited access to tables allowing easier entry at the door.</p>
                        </animated.div>
                        <animated.div ref={ref7} className={`feature_segment_semi ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                        style={springPropsDelay}
                        >
                            <img alt={'alt-text'} src={inventorySvg} />
                            <b className={`text_feature_header ${isLight ? 'text-black' : 'text-white'}`}>Inventory management</b>
                            <p className={`text_feature_small ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                        </animated.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
