import React, { useEffect, useRef, useState } from "react";
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useScroll } from '@react-spring/web'
import TopNav from "components/app/web/customer/TopNav";
import Nav_BG from "../assets/icons/landing/nav_bg.svg"
import BallRoller from "../assets/images/svg/BallRoller"
import HeroSection from "components/app/web/customer/Hero";
import HeroFooter from "components/app/web/customer/HeroFooter";
import FeaturedClubs from "components/app/web/customer/FeaturedClubs";
import Testimonia from "components/app/web/customer/Testimonia";
import Footer from "components/app/web/Footer";
import Benefits from "components/app/web/customer/Benefits";
import DashboardLayout from "layouts/DashboardLayout";
import HowItWorks from "components/app/web/customer/HowItWorks"
import { useTheme } from "context/useAppTheme";
import Test from "components/app/web/customer/Test";


interface Props { }

const LandingPageMobile: React.FC<Props> = (props: Props) => {
  const midHeroRef = useRef<HTMLDivElement | null>(null);
  const keyFeatures = useRef<HTMLDivElement | null>(null)
  const Howitworks = useRef<HTMLDivElement | null>(null)
  const footerRef = useRef<HTMLDivElement | null>(null)
  const homeRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [navOpacity, setNavOpacity] = useState(0)

  const { setPercentageScroll } = useTheme()

  useScroll({
    container: containerRef.current as any,
    onChange: ({ value: { scrollYProgress } }) => {
      setNavOpacity(scrollYProgress)
      setPercentageScroll(scrollYProgress)
    },
    default: {
      immediate: true,
    },
  })

  const scrollToElement = (node: any) => {
    scrollIntoView(node, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'start',
    });
  }

  useEffect(() => {
    let body = document.querySelector('body')
    body?.addEventListener('scroll', () => {
      console.log('scrolled')
    })
  }, [])


  const moveToSections = (value: string) => {
    switch (value) {
      case 'hero':
        scrollToElement(midHeroRef.current)
        break;
      case 'features':
        scrollToElement(keyFeatures.current)
        break;
      case 'howitworks':
        scrollToElement(Howitworks.current)
        break;
      case 'contact_us':
        scrollToElement(footerRef.current)
        break;
      default:
        scrollToElement(homeRef.current)
        break;
    }
  }

  return (
    <DashboardLayout>
      <div className="hero_bg_wrapper_customer">
        <div>
          <img alt={'float-background'} src={Nav_BG} className="absolute left-[0%] top-0" />
          <TopNav moveToSections={moveToSections} navOpacity={navOpacity} />
        </div>
        <div ref={midHeroRef}>
          <HeroSection />
        </div>
        <div >
          <FeaturedClubs />
        </div>
      </div>
      <div>
        <Benefits />
      </div>
      <div ref={Howitworks}>
        <HowItWorks />
      </div>
      <div>
        <Test/>
      </div>
      <div className="relative">
        <div className="absolute top-[-50%] right-0 z-[-1]">
          <BallRoller scrollValue={navOpacity} />
        </div>
        <Testimonia />
      </div>
      <div>
        <HeroFooter />
      </div>
      <div ref={footerRef}>
        <Footer moveToSections={moveToSections} />
      </div>
    </DashboardLayout>
  );
};

export default LandingPageMobile;
