import { useTheme } from "context/useAppTheme";
import React from "react";
import DashboardLayout from "layouts/DashboardLayout";
import EffortLess from "assets/images/svg/effortlessSvg.svg";
import EnhanceSvg from "assets/images/svg/enhancedSvg.svg";
import QrcodeSvg from "assets/images/svg/QrcodeSvg.svg";
import flexibleSvg from "assets/images/svg/flexibleSvg.svg";
import CentralizedSvg from "assets/images/svg/CentralizedSvg.svg";
import realTimeSvg from "assets/images/svg/realTimeSvg.svg"
import inventorySvg from "assets/images/svg/inventorySvg.svg"
import seamleassSvg from "assets/images/svg/seamleassSvg.svg"
import WaitlistForm from "components/app/web/waitlist/WaitlistForm";
import Nav_BG from "../assets/icons/landing/nav_bg.svg"
import App_logo from "../assets/images/waitlist/appLogo.svg"
import waitlist_bottom_bg from "../assets/images/waitlist/cuate.png"
import waitlist_right_bottom_bg from "../assets/images/waitlist/bro.png"


interface Props { }

const Waitlist: React.FC<Props> = (props: Props) => {

    const { isLight } = useTheme()


    return (
        <DashboardLayout>
            <div className="w-full flex justify-center h-[100vh] overflow-y-auto py-6">
                {/* start of adding floating images */}
                <img alt={'float-background'} src={Nav_BG} className="absolute left-[0%] top-0" />
                <img alt={'float-background'} src={waitlist_bottom_bg} className="absolute left-[0%] bottom-0" />
                <img alt={'float-background'} src={waitlist_right_bottom_bg} className="absolute right-[0%] bottom-0" />
                {/* End of adding floating images */}

                <div className="flex flex-wrap max-w-[2000px] px-3 lg:px-6 items-center">
                    <div className="w-full xl:w-6/12">
                        <img alt={'alt-text'} className="w-full max-w-[200px]" src={isLight ? App_logo : App_logo} />
                        <p className={`waitlist_title ${isLight ? 'text-black' : 'text-white'}`}>Let Our App Handle the Tables,
                            While You Focus on Delivering Unforgettable Nights.</p>
                        <div className="w-full flex flex-wrap justify-between">
                            <p className={`my-4 text-night_greg/70 w-full text-[20px] mt-12`}>Key features</p>
                            <div className={`feature_waitlist  ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={EffortLess} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Effortless Reservation management</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Handle table reservations and ticket sales from one central platform.</p>
                            </div>
                            <div className={`feature_waitlist ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={EnhanceSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Enhanced User experience</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Users get instant confirmation of their table bookings and can pre-order bottle service.</p>
                            </div >
                            <div className={`feature_waitlist ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={seamleassSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Seamless support handling</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                            </div>
                            <div className={`feature_waitlist ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={QrcodeSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>QR code entry</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>QR code ticketing for limited access to tables allowing easier entry at the door.</p>
                            </div>
                            <div className={`feature_waitlist ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={flexibleSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Flexible pricing</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Adjustable pricing to accommodate high traffic weekends and increase profits.</p>
                            </div>
                            <div className={`feature_waitlist ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={CentralizedSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Centralized payments</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                            </div>
                            <div className={`feature_segment_semi ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={realTimeSvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Monitor data in realtime</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>QR code ticketing for limited access to tables allowing easier entry at the door.</p>
                            </div>
                            <div className={`feature_segment_semi ${isLight ? 'bg-night_light_blue' : 'bg-night_blue_800'}`}
                            >
                                <img alt={'alt-text'} src={inventorySvg} />
                                <b className={`text_feature_header_waitlist ${isLight ? 'text-black' : 'text-white'}`}>Inventory management</b>
                                <p className={`text_feature_small_waitlist ${isLight ? 'text-black' : 'text-night_pale_blue'}`}>Billing completed in app to track profits and booking history.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full xl:w-6/12 flex">
                        <WaitlistForm setModal={function (val: boolean): void {
                            throw new Error("Function not implemented.");
                        } } />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default Waitlist;
