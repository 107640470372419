import React, { useRef, useState } from "react";
import Applogo from '../../assets/icons/Applogo.svg';
import ApplogoLight from "../../assets/icons/AppLogoLight.svg"
import { useTheme } from "context/useAppTheme";
import ToggleMode from "components/widget/ToggleMode";
import ToggleSide from "components/widget/ToggleSides";
import Modal from "../widget/Modal";
import WaitlistForm from "components/app/web/waitlist/WaitlistForm";

interface Props {
    moveToSections: Function,
    navOpacity: number
}


const TopNav: React.FC<Props> = (props: Props) => {
    const [dropDown, setDropDown] = useState<boolean>(true)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const { moveToSections, navOpacity } = props
    const { isLight } = useTheme()
    const hamburger = useRef<HTMLDivElement | null>(null)

    const setNavAction = () => {
        if (dropDown) {
            hamburger.current?.classList.add('is-active');
            setDropDown(!dropDown);
        } else {
            hamburger.current?.classList.remove('is-active');
            setDropDown(!dropDown);
        }
        //    setDropDown(!dropDown)
    }

    const setMoveSection = (val: string) => {
        moveToSections(val)
        setDropDown(!dropDown);
        hamburger.current?.classList.remove('is-active');
    }

    const openWaitlist=()=>{
        setOpenModal(true);
        setDropDown(!dropDown);
        hamburger.current?.classList.remove('is-active');
    }

    return (
        <div className="px-3 pt-3 lg:px-10 xl:px-20 fixed top-0 left-0 p-4 w-full z-[100] transition duration-300 backdrop-blur-lg"
            style={{ backgroundColor: `${isLight ? 'rgba(234, 232, 254,' : 'rgba(17, 13, 33,'}${navOpacity < 0.01 && dropDown ? 0 : 0.7})` }}
        >
            <div className="nav_top_container lg:py-1">
                <div className="logo_container">
                    <img alt="" src={isLight ? ApplogoLight : Applogo} />
                    <button
                        onClick={() => setNavAction()}
                    >
                        {/* <img alt="" src={hamburdger} /> */}
                        {/* <Hamburger color={isLight ? 'black' : 'white'} /> */}
                        <div className="three col">
                            <div className="hamburger" ref={hamburger} id="hamburger-6">
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                                <span style={{ backgroundColor: isLight ? 'black' : 'white' }} className="line"></span>
                            </div>
                        </div>
                    </button>
                </div>
                <div className={`responsive_nav ${dropDown ? 'max_h_0' : 'max_h_screen'}`}>
                    <div className="links_container pt-6 lg:pt-0 w-full">
                        <div className="flex flex-wrap w-full lg:justify-center items-center lg:w-7/12 nav_link_wrapper lg:flex-1">
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'}`}
                                onClick={() => setMoveSection('hero')}
                            >
                                <span className="text_place">Home</span>
                                <b className="text_replace">Home</b>
                            </div>
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'}`}
                                onClick={() => setMoveSection('features')}
                            >
                                <span className="text_place">Key features</span>
                                <b className="text_replace">Key features</b>
                            </div>
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'}`}
                                onClick={() => setMoveSection('howitworks')}
                            >
                                <span className="text_place">How it works</span>
                                <b className="text_replace">How it works</b>
                            </div>
                            <div
                                className={`${isLight ? 'test_btn_light' : 'test_btn'}`}
                                onClick={() => setMoveSection('contact_us')}
                            >
                                <span className="text_place">Contact</span>
                                <b className="text_replace">Contact</b>
                            </div>
                        </div>
                        <div className="flex items-center flex-wrap mt-24 lg:mt-0">
                            <div className="lg:hidden xl:flex">
                                <div className="flex">
                                    <ToggleSide />
                                </div>
                            </div>
                            <span className="inline-flex w-full lg:w-auto  justify-end my-3 lg:my-0 lg:mx-5">
                                <ToggleMode />
                            </span>
                            <div
                                onClick={() => openWaitlist()}
                                className={` cursor-pointer ${isLight ? 'sign_up_btn draw_border_light' : 'sign_up_btn draw_border'}`}>
                                Join Waitlist
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Modal isOpen={openModal} setOpen={setOpenModal}>
                <WaitlistForm setModal={setOpenModal}></WaitlistForm>
            </Modal>

        </div>
    );
};

export default TopNav;
