import * as React from "react";
const InstagramIcon = (props: any) => (
  <svg
    width={44}
    height={44}
    viewBox="0 0 34 35"
    fill="none"
    {...props}
  >
    <path
      d="M19.281 17.8443C19.2724 18.2931 19.1308 18.7292 18.8741 19.0974C18.6174 19.4656 18.2571 19.7493 17.839 19.9126C17.4209 20.0758 16.9637 20.1112 16.5254 20.0143C16.0872 19.9174 15.6875 19.6926 15.3772 19.3683C15.0668 19.044 14.8597 18.6349 14.7822 18.1928C14.7046 17.7507 14.76 17.2955 14.9414 16.885C15.1229 16.4744 15.4221 16.1269 15.8012 15.8866C16.1803 15.6463 16.6222 15.524 17.071 15.5351C17.6672 15.5572 18.2315 15.8107 18.644 16.2417C19.0566 16.6728 19.2851 17.2476 19.281 17.8443Z"
      fill={props.color || "white"}
    />
    <path
      d="M20.9141 10.7467H13.2287C12.4682 10.7467 11.7389 11.0488 11.2012 11.5865C10.6634 12.1243 10.3613 12.8536 10.3613 13.614V21.4723C10.3613 21.8488 10.4355 22.2217 10.5796 22.5696C10.7237 22.9174 10.9349 23.2335 11.2012 23.4998C11.4674 23.7661 11.7835 23.9773 12.1314 24.1214C12.4793 24.2655 12.8521 24.3396 13.2287 24.3396H20.9141C21.2906 24.3396 21.6635 24.2655 22.0114 24.1214C22.3592 23.9773 22.6753 23.7661 22.9416 23.4998C23.2078 23.2335 23.4191 22.9174 23.5631 22.5696C23.7072 22.2217 23.7814 21.8488 23.7814 21.4723V13.6282C23.7829 13.2506 23.7099 12.8764 23.5665 12.527C23.4231 12.1777 23.2121 11.86 22.9458 11.5924C22.6794 11.3247 22.3628 11.1122 22.0142 10.9671C21.6656 10.822 21.2917 10.7471 20.9141 10.7467ZM17.0707 21.6876C16.3099 21.7048 15.5612 21.4949 14.9202 21.0847C14.2792 20.6745 13.775 20.0826 13.4719 19.3846C13.1688 18.6866 13.0805 17.9141 13.2183 17.1657C13.3562 16.4173 13.7139 15.7269 14.2458 15.1827C14.7778 14.6385 15.4598 14.2651 16.2048 14.1103C16.9499 13.9554 17.7242 14.026 18.429 14.3131C19.1337 14.6002 19.7369 15.0908 20.1617 15.7222C20.5864 16.3537 20.8133 17.0974 20.8135 17.8584C20.8193 18.3556 20.7271 18.8491 20.5421 19.3107C20.3571 19.7722 20.083 20.1928 19.7354 20.5484C19.3878 20.904 18.9736 21.1877 18.5164 21.3831C18.0591 21.5786 17.5679 21.6821 17.0707 21.6876ZM21.23 14.0305C21.1365 14.0306 21.044 14.0119 20.9578 13.9757C20.8716 13.9395 20.7935 13.8865 20.7281 13.8197C20.6626 13.753 20.6112 13.6738 20.5767 13.587C20.5422 13.5001 20.5254 13.4072 20.5273 13.3137C20.5273 13.1236 20.6029 12.9413 20.7373 12.8068C20.8717 12.6724 21.054 12.5969 21.2442 12.5969C21.4343 12.5969 21.6166 12.6724 21.751 12.8068C21.8855 12.9413 21.961 13.1236 21.961 13.3137C21.9638 13.4149 21.9447 13.5155 21.905 13.6087C21.8654 13.7018 21.806 13.7853 21.7311 13.8533C21.6561 13.9214 21.5674 13.9725 21.4709 14.0031C21.3744 14.0337 21.2724 14.043 21.1719 14.0305H21.23Z"
      fill={props.color || "white"}
    />
    <path
      d="M17.0717 3.33331C13.3144 3.31452 9.70362 4.78906 7.03357 7.43254C4.36352 10.076 2.85295 13.6719 2.83416 17.4291C2.81538 21.1864 4.28991 24.7972 6.93339 27.4672C9.57688 30.1373 13.1728 31.6479 16.93 31.6666C18.7904 31.6759 20.6344 31.3187 22.3567 30.6154C24.0791 29.912 25.646 28.8763 26.9681 27.5674C28.2902 26.2585 29.3415 24.702 30.062 22.9868C30.7826 21.2715 31.1582 19.4312 31.1675 17.5708C31.1768 15.7104 30.8196 13.8664 30.1162 12.1441C29.4129 10.4217 28.3772 8.85479 27.0683 7.53271C25.7593 6.21064 24.2028 5.15932 22.4876 4.43879C20.7724 3.71825 18.9321 3.34261 17.0717 3.33331ZM25.6751 21.3278C25.679 21.9634 25.5568 22.5934 25.3154 23.1814C25.0741 23.7693 24.7184 24.3035 24.269 24.753C23.8197 25.2025 23.2856 25.5583 22.6977 25.7999C22.1098 26.0414 21.4798 26.1638 20.8442 26.1601H13.3019C12.6665 26.1636 12.0366 26.0411 11.4488 25.7996C10.861 25.5581 10.327 25.2025 9.87754 24.7532C9.42813 24.3039 9.0723 23.7699 8.83063 23.1822C8.58897 22.5945 8.46628 21.9647 8.46966 21.3292V13.7855C8.46571 13.1499 8.58796 12.5199 8.82932 11.9319C9.07067 11.344 9.42634 10.8097 9.87569 10.3603C10.325 9.91077 10.8591 9.55495 11.447 9.31342C12.0349 9.07189 12.6649 8.94946 13.3005 8.95322H20.8442C21.4797 8.94946 22.1096 9.07185 22.6974 9.31329C23.2852 9.55473 23.8192 9.91042 24.2685 10.3598C24.7179 10.8091 25.0736 11.3431 25.315 11.9309C25.5565 12.5187 25.6788 13.1486 25.6751 13.7841V21.3278Z"
      fill={props.color || "white"}
    />
  </svg>
);
export default InstagramIcon;
