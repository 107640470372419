import { createContext, useContext } from 'react';

// Define the shape of the context
interface ThemeContextType {
    theme: 'light' | 'dark';
    toggleTheme: (val?: string) => void;
    isLight: boolean,
    scrollPercent: string | number;
    setPercentageScroll: (val: string)=> void
}

// Create a default value for the context (null to be set later)
export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
