import * as React from "react";
const TwitterIcon = (props: any) => (
  <svg
    width={35}
    height={35}
    viewBox="0 0 28 29"
    fill="none"
    {...props}
  >
    <circle cx={14} cy={14.5} r={14} fill={props.bgcolor || "white"}  />
    <path
      d="M17.8347 8.5H19.8793L15.4127 13.5833L20.6673 20.5H16.5527L13.3307 16.3047L9.64332 20.5H7.59665L12.3747 15.0633L7.33398 8.5H11.5527L14.466 12.3347L17.8347 8.5ZM17.1173 19.2813H18.2507L10.9367 9.65467H9.72065L17.1173 19.2813Z"
      fill={props.color || "white"} 
    />
  </svg>
);
export default TwitterIcon;
