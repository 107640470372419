/* eslint-disable */
import { useTheme } from 'context/useAppTheme';
import  {  ReactNode, useEffect } from 'react'

interface Props {
    children: ReactNode;
}

function DashboardLayout(props: Props) {

    const { isLight } = useTheme()

    return (
        <div className={`dashboard_container relative`} id="container">
            <div className={`dark_bg_layout ${isLight ? 'move-left' : 'move-origin'}`}></div>
                {props.children}
        </div>
    )
}

export default DashboardLayout
