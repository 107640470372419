import * as React from "react";

const TickTok = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 28 29"
    fill="none"
    {...props}
  >
    <circle cx={14} cy={14.5} r={14} fill={props.bgcolor || "white"} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3167 7.48047C15.0842 7.46875 15.8459 7.4746 16.6076 7.46875C16.6545 8.36523 16.9767 9.27928 17.6329 9.91207C18.2891 10.5625 19.2149 10.8613 20.1172 10.9609V13.3222C19.2735 13.2929 18.4239 13.1171 17.6564 12.7538C17.3224 12.6015 17.0119 12.4081 16.7072 12.2089C16.7013 13.9198 16.713 15.6308 16.6955 17.3358C16.6486 18.1561 16.3791 18.9706 15.9045 19.6444C15.1369 20.7694 13.8069 21.5018 12.4417 21.5252C11.6039 21.5721 10.766 21.3436 10.0512 20.9217C8.86768 20.2245 8.03567 18.9471 7.91264 17.576C7.90092 17.2831 7.89506 16.9901 7.90677 16.703C8.01224 15.5897 8.563 14.5233 9.41844 13.7968C10.391 12.9531 11.7504 12.5488 13.0218 12.789C13.0335 13.6562 12.9984 14.5233 12.9984 15.3905C12.4183 15.203 11.7386 15.2558 11.2289 15.6073C10.8598 15.8476 10.5785 16.2167 10.4321 16.6327C10.309 16.9315 10.3442 17.2596 10.35 17.576C10.4906 18.537 11.4164 19.3456 12.4007 19.2577C13.057 19.2518 13.6839 18.8709 14.0237 18.3143C14.135 18.121 14.2581 17.9217 14.2639 17.6932C14.3225 16.6444 14.2991 15.6015 14.3049 14.5526C14.3108 12.1913 14.2991 9.83591 14.3167 7.48047Z"
      fill={props.color || "white"}
    />
  </svg>
);
export default TickTok;
