import * as React from "react";
const FacebookIcon = (props: any) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 34 35"
    fill="none"
    {...props}
  >
    <path
      d="M31.1673 17.5C31.1673 9.68004 24.8207 3.33337 17.0007 3.33337C9.18065 3.33337 2.83398 9.68004 2.83398 17.5C2.83398 24.3567 7.70732 30.0659 14.1673 31.3834V21.75H11.334V17.5H14.1673V13.9584C14.1673 11.2242 16.3915 9.00004 19.1257 9.00004H22.6673V13.25H19.834C19.0548 13.25 18.4173 13.8875 18.4173 14.6667V17.5H22.6673V21.75H18.4173V31.5959C25.5715 30.8875 31.1673 24.8525 31.1673 17.5Z"
      fill={props.color || "white"}
    />
  </svg>
);
export default FacebookIcon;
