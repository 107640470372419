import React, { useEffect, useState } from "react";
import { useTheme } from "context/useAppTheme";
import HeroBg from "../../../assets/images/hero_bg.svg"
import HeaderStroke from "../../../assets/images/svg/HeaderStroke.svg"
import { gsap } from 'gsap';

interface Props { }


const HeroSection: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()
    const [text] = useState("Hello there people")

    useEffect(() => {
        var textWrapper = document.querySelector(".titulo") as any
        textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            "<span class='letra'>$&</span>"
        )
        gsap.timeline()
            .from('.titulo .letra', {
                y: 60,  // Equivalent to translateY in Anime.js
                opacity: 0,  // Start with opacity 0
                duration: 1.7,  // Duration in seconds
                ease: "expo.out",  // Equivalent to easeOutExpo
                stagger: {
                    amount: 1.2,  // Total time for staggering (can be adjusted)
                    //@ts-ignore
                    start: 0.4,  // Delay start
                    from: "start",  // Stagger from start
                }
            });
    }, [text]);

    return (
        <div className="px-3 pt-3 relative flex justify-center" >
            <div className="flex justify-center mt-28 flex-wrap max-w-[1500px] relative">
                <div className=" md:w-8/12 text-center flex flex-wrap justify-center relative pt-16">
                    <img alt={'alt-text'} className="absolute bottom-0 left-0 -z-[1] w-[150%]" src={HeroBg} />
                    <img alt={'alt-text'} className="absolute -bottom-[20%] left-0 -z-[1] w-[150%]" src={HeroBg} />
                    <p className={`hero_heading overflow-y-hidden ${isLight ? 'text-night_blue' : 'text-night_pale_blue'}`}>
                        <span>B</span>oost Your
                        <b className="relative"> Club Success
                            <img alt={'alt-text'} src={HeaderStroke}  className="absolute bottom-[3px] right-0 -z-[1] w-[90%]"/>
                        </b>
                        with Our Reservation <span className="titulo">System</span>
                    </p>
                    <p className={`hero_title max-w-[600px] ${isLight ? 'text-night_blue' : 'text-night_pale_blue'}`}>
                        Streamline bookings, increase revenue, and enhance customer experience with our seamless reservation platform.
                    </p>
                    <div className="mt-7 flex items-center flex-wrap justify-center">
                        <button className={`ennroll_btn ${isLight ? 'night_enroll_btn draw_border_light' : 'night_enroll_btn'}`}>Enroll for free now</button>
                        <button className={`ennroll_btn ${isLight ? 'request_enroll_btn draw_border_light' : 'request_enroll_btn'}`}>Request information </button>
                    </div>
                    <div className="mt-10 mb-16">
                        {
                            isLight ? <img alt={'alt-text'} className="shadow" src={'https://res.cloudinary.com/azudi/image/upload/v1732999730/Dashbaord_1_mqws2r.png'} />
                                : <img alt={'alt-text'} className="shadow" src={'https://res.cloudinary.com/azudi/image/upload/v1732999638/Group_1000002785_gf3jef.png'} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
