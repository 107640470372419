import { useTheme } from 'context/useAppTheme'
import LightIcon from "assets/icons/LightIcon.svg"
import DarkIcon from "assets/icons/DarkIcon.svg"

interface Props { }

function ToggleMode(props: Props) {
    const { toggleTheme, isLight } = useTheme()

    const switchMode = () => {
        toggleTheme()
    }

    return (
        <div className={`checkbox ${ isLight ? 'border-night_greg' : 'border-transparent'}`} onClick={() => switchMode()}
        style={{ background: isLight ? "rgba(233, 233, 233, 1)" : 'rgba(98, 98, 98, 1)'}}
        >
            <span style={{ left: isLight ? '0%' : '0%' }}>
                <img alt={'switch-mode-img'} className='w-full h-full' src={ isLight ?  LightIcon : DarkIcon } />
            </span>
        </div>
    )
}

export default ToggleMode
