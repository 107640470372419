
interface Props {
    scrollValue: number
 }

function BallerRound(props: Props) {
    const { scrollValue } = props

    return (
         <svg width="775" height="1356" viewBox="0 0 775 1356" fill="none" style={{ strokeDasharray: `${3000 * (scrollValue/2.5)} 1000` }}>
            <rect id="animated-rect" opacity="0.14" x="945.115" y="1348.6" width="1021" height="1021" rx="510.5" transform="rotate(-156.722 945.115 1348.6)" stroke="#4500D6" stroke-width="11" />
            <circle cx="534.022" cy="188.73" r="27.5" transform="rotate(-156.722 534.022 188.73)" fill="#32205E" />
        </svg>
    )
}

export default BallerRound


