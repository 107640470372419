import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useTheme } from "context/useAppTheme";
import RatingsIcon from "assets/icons/RatingIcon";
import RatingsNone from "assets/icons/RatingsNull";

// import './styles.css';

interface Props {

}

const Testimonia: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()
    const [testimonia] = useState([
        {
            name: "Dan S",
            ratings: 3,
            testimonia: `Grocipe has changed cooking for me for life! I feel like I can accomplish making a meal every night for my family even after being at work all day.`
        },
        {
            name: "Kristine S",
            ratings: 4,
            testimonia: `The AI feature of this app makes cooking so much fun! I can find recipes and add them to my recipe book all from one place. I also love being able to find substitutions for my allergy requirements.`
        },
        {
            name: "Hannah H",
            ratings: 4,
            testimonia: `The worst part about cooking for me is grocery shopping. With Grocipe it makes trips to the store bearable and less frequent.`
        },
        {
            name: "Nick D",
            ratings: 2,
            testimonia: `This app has a cool way of rating recipes health level making it less focused on calories and more on overall nutrition.`
        },
        {
            name: "Sarah N",
            ratings: 1,
            testimonia: `With inflation sometimes it feels easier to order food out since going to the grocery store is so expensive but after using this app, our food budget has decreased tremendously. We cook in the house almost every day!`
        }
    ])

    return (
        <div className="mid_hero_container mt-12 mb-24">
            <div className="flex justify-center mt-12">
                <div className="lg:w-10/12 md:w-11/12 w-full max-w-[1500px] flex flex-wrap items-center px-3">
                    <p className={`mb-0 text-[24px] mt-5 ${isLight ? 'text-black' : 'text-white'}`}>Club owners testimonials</p>
                    <div className="w-full mt-5 overflow-x-hidden">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView="auto"
                            className="mySwiper"
                        >
                            {/* <div className="scoller_right"> */}
                            {
                                (testimonia || []).map((item: any, index) => {
                                    return (
                                        <SwiperSlide className="w-full max-w-[500px]" style={{ marginRight: '20px' }}>
                                            <div key={index + 'testimonia'} className="testimonia_box">
                                                <p className={`whitespace-normal text-left font-semibold ${isLight ? 'text-black' : 'text-white'}`}>
                                                    Effortless booking management
                                                </p>
                                                <p className={`text-[14px] mt-3 ${isLight ? 'text-black' : 'text-night_greg'}`}>
                                                    Handle table reservations and ticket sales from one central platform.
                                                </p>
                                                <div className="my-3 flex items-center">
                                                    {
                                                        Array.from({ length: 5 }).map((rating, index) => {
                                                            let ratingsIndex = item.ratings - 1

                                                            return (<span className="mx-1">
                                                                {index <= ratingsIndex && <RatingsIcon color={isLight ? 'black' : null} />}
                                                                {index > ratingsIndex && <RatingsNone color={isLight ? 'rgba(0, 0, 0, 0.35)' : 'rgba(255, 255, 255, 0.35)'} />}
                                                            </span>)
                                                        })
                                                    }
                                                </div>
                                                <div className="testimonia_info">
                                                    <div className="text-[14px]">
                                                        <b className={`block w-full uppercase text-right ${isLight ? 'text-black' : 'text-white'}`}>Jermy Wilson</b>
                                                        <p className={`text-right ${isLight ? 'text-black' : 'text-white'}`}>
                                                            ABC Club
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }


                        </Swiper>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonia;
