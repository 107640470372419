import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import LimSvg from "../../../assets/images/svg/OmnLogo.svg"
import AquaSvg from "../../../assets/images/svg/AquaSvg.svg"
import { useTheme } from 'context/useAppTheme';

const Ticker: React.FC = () => {
    const tickerRef = useRef<HTMLDivElement | null>(null);
    const loop = useRef<gsap.core.Timeline | null>(null);
    const timeout = useRef<number | null>(null);
    const { isLight } = useTheme()

    // Horizontal Loop function similar to the original
    const horizontalLoop = (items: HTMLElement[], config: any) => {
        config = config || {};
        const tl = gsap.timeline({
            repeat: config.repeat,
            paused: config.paused,
            defaults: { ease: 'none' },
            onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100) as any,
        });

        const length = items.length;
        const startX = items[0].offsetLeft;
        const widths: number[] = [];
        const xPercents: any[] = [];
        const times = [];
        let totalWidth: any;
        const pixelsPerSecond = (config.speed || 1) * 50;

        gsap.set(items, {
            xPercent: (i, el) => {
                const w = (widths[i] = parseFloat(gsap.getProperty(el, 'width', 'px') as any));
                //@ts-ignore
                xPercents[i] = (parseFloat(gsap.getProperty(el, 'x', 'px')) / w) * 100 + gsap.getProperty(el, 'xPercent');
                return xPercents[i];
            },
        });

        gsap.set(items, { x: 0 });
        totalWidth = items[length - 1].offsetLeft + items[length - 1].offsetWidth - startX;
        items.forEach((item, i) => {
            const curX = (xPercents[i] / 100) * widths[i];
            const distanceToStart = item.offsetLeft + curX - startX;
            const distanceToLoop = distanceToStart + widths[i];
            tl.to(
                item,
                {
                    xPercent: ((curX - distanceToLoop) / widths[i]) * 100,
                    duration: distanceToLoop / pixelsPerSecond,
                },
                0
            )
                .fromTo(
                    item,
                    { xPercent: ((curX - distanceToLoop + totalWidth) / widths[i]) * 100 },
                    {
                        xPercent: xPercents[i],
                        duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                        immediateRender: false,
                    },
                    distanceToLoop / pixelsPerSecond
                )
                .add(`label${i}`, distanceToStart / pixelsPerSecond);
            times[i] = distanceToStart / pixelsPerSecond;
        });

        return tl;
    };

    useEffect(() => {
        const items = gsap.utils.toArray<HTMLElement>('.ticker__entry');
        loop.current = horizontalLoop(items, { paused: true, repeat: -1 });
        gsap.delayedCall(1, () => loop.current?.play());

        items.forEach((item, index) => {
            item.addEventListener('mouseenter', () => {
                timeout.current = window.setTimeout(() => {
                    loop.current?.pause();
                    loop.current?.toIndex(index, { duration: 0.3 });
                }, 0);
            });
            item.addEventListener('mouseleave', () => {
                if (timeout.current) clearTimeout(timeout.current);
                loop.current?.play();
            });
        });

        return () => {
            loop.current?.kill();
        };
    }, []);

    return (
        <div className="connect_hero_container w-full">

            <div className="flex justify-center mt-0 lg:pt-12 lg:mb-20">
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center overflow-x-hidden px-3 lg:p-0">
                <p className={`mb-8 text-[24px] mt-5 ${ isLight ? 'text-black' : 'text-white'}`}>Trusted partners</p>
                    <div className="wrap mb-12">
                        <div className="ticker" ref={tickerRef}>
                            <div className="ticker__inner">
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={AquaSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>
                                <div className="ticker__entry">
                                    <img alt={'alt-text'} src={LimSvg} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ticker;
