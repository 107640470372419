import React, { useEffect, useRef, useState } from "react";
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useScroll } from '@react-spring/web'
import TopNav from "components/app/TopNav";
import Nav_BG from "../assets/icons/landing/nav_bg.svg"
import Create_BG from "../assets/images/cuate.png"
import howitworks from "../assets/images/how-it-works.png"
import Rafiki from "../assets/images/rafiki.png"
import HeroSection from "components/app/web/Hero";
import HowItWorks from "components/app/web/HowItWorks";
import TrustedPartners from "components/app/web/Partners";
import Features from "components/app/web/Features";
import Testimonia from "components/app/web/Testimonia";
import Footer from "components/app/web/Footer";
import Benefits from "components/app/web/Benefits";
import DashboardLayout from "layouts/DashboardLayout";


interface Props { }

const Dashboard: React.FC<Props> = (props: Props) => {
  const midHeroRef = useRef<HTMLDivElement | null>(null);
  const keyFeatures = useRef<HTMLDivElement | null>(null)
  const Howitworks = useRef<HTMLDivElement | null>(null)
  const footerRef = useRef<HTMLDivElement | null>(null)
  const homeRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [navOpacity, setNavOpacity] = useState(0)


  useScroll({
    container: containerRef.current as any,
    onChange: ({ value: { scrollYProgress } }) => {
      setNavOpacity(scrollYProgress)
    },
    default: {
      immediate: true,
    },
  })

  const scrollToElement = (node: any) => {
    scrollIntoView(node, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'start',
    });
  }

  useEffect(() => {
    let body = document.querySelector('body')
    body?.addEventListener('scroll', () => {
      console.log('scrolled')
    })
  }, [])


  const moveToSections = (value: string) => {
    switch (value) {
      case 'hero':
        scrollToElement(midHeroRef.current)
        break;
      case 'features':
        scrollToElement(keyFeatures.current)
        break;
      case 'howitworks':
        scrollToElement(Howitworks.current)
        break;
      case 'contact_us':
        scrollToElement(footerRef.current)
        break;
      default:
        scrollToElement(homeRef.current)
        break;
    }
  }

  return (
    <DashboardLayout>
      <div className="hero_bg_wrapper">
        <div>
          <img alt={'float-background'} src={Nav_BG} className="absolute left-[0%] top-0" />
          <TopNav moveToSections={moveToSections} navOpacity={navOpacity} />
        </div>
        <div ref={midHeroRef}>
          <HeroSection />
        </div>
      </div>

      <div className="relative">
        <img alt={"create background"} className="absolute -z-[1] w-full min-w-[1200px] top-0 left-0 object-cover" src={Create_BG} />
        <div>
          <Benefits />
        </div>
        <div ref={keyFeatures}>
          <Features />
        </div>
      </div>
      <div className="relative overflow-y-hidden overflow-x-hidden">
        <img alt={"create background"} className="absolute -z-[1] w-full min-w-[1200px] top-0 left-0 object-cover" src={howitworks} />
        <div ref={Howitworks}>
          <HowItWorks />
        </div>
      </div>

      <div className="relative overflow-y-hidden">
      <img alt={"create background"} className="absolute -z-[1] w-full min-w-[1200px] top-0 left-0 object-cover" src={Rafiki} />
        <div >
          <TrustedPartners />
        </div>
        <div>
          <Testimonia />
        </div>
        <div ref={footerRef}>
          <Footer moveToSections={moveToSections} />
        </div>
      </div>

    </DashboardLayout>
  );
};

export default Dashboard;
