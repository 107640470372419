import { useEffect, useMemo, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import route from "./router";
import stack from "./stack";
import NotFound from "components/widget/404";
import { ThemeContext } from "context/useAppTheme";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const RootRoutes = () => {
  const landing = route.filter((item, index) => {
    return item?.stack === stack.LANDING || item?.stack === stack.APP;
  });

  // check if user is already saved to storage

  const [theme, setTheme] = useState<'light' | 'dark'>('dark');
  const [scrollPercent, setscrollPercent] = useState<string | number>('');


  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      toggleTheme('dark')
    } else {
      toggleTheme('light')
    }
  }, [])

  const toggleTheme = (val?: any) => {
    if (val) {
      setTheme(val)
      return
    }
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const setPercentageScroll = (val: string) => {
    setscrollPercent(val)
  };

  const isLight = useMemo(() => {
    return theme === "light";
  }, [theme]);


  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isLight, scrollPercent, setPercentageScroll }}>
      <BrowserRouter>
        <Routes>
          {
            landing.map((item, index: number) => {
              const { Page, route } = item;
              return <Route key={index} path={route} element={<Page />} />;
            })
          }
          <Route path='*' element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </ThemeContext.Provider>
  );
};

export default RootRoutes;
