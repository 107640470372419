import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "context/useAppTheme";
import "./webStyles.scss";
import BenefitIncrease from "../../../assets/images/svg/benefitIncrease.svg";
import BenSatisfy from "../../../assets/images/svg/benSatisfy.svg";
import Bengrowth from "../../../assets/images/svg/bengrowth.svg";
import Applogo from 'assets/icons/Applogo.svg';
import ApplogoLight from "assets/icons/AppLogoLight.svg"
import Sidebubble from "../../../assets/images/ball_edge.png"
import BenefitSpiral from "../../../assets/images/svg/BenefitSpiral.svg"

interface Props { }

const Benefits: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const beIncreaseRef = useRef<HTMLParagraphElement>(null);
    const beGrowthRef = useRef<HTMLParagraphElement>(null);
    const beSatisfyRef = useRef<HTMLParagraphElement>(null);
    const [triggered, setTrigger] = useState('')


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        console.log(triggered)
                        if(triggered !== "triggered"){
                            loopCount(beIncreaseRef, 15, 40);
                            loopCount(beGrowthRef, 25, 30);
                            loopCount(beSatisfyRef, 60, 20);
                            setTrigger('triggered')
                            observer.disconnect()
                        }
                    }
                });
            },
            {
                threshold: 1, // Trigger when 50% of the component is in view
            }
        );

        // Observe the target component
        if (wrapperRef.current) {
            observer.observe(wrapperRef.current);
        }

        // Cleanup the observer when the component unmounts
        return () => {
                observer.disconnect();
        };
    }, [triggered]);

    const loopCount = (element: any, countStop: number, speedLimit: number) => {
        let count = 0;

        const timer = () => {
            count++;
            // Update the content of the paragraph using the ref
            if (element.current) {
                element.current.innerHTML = `${count}%`; // Assuming you want to show a percentage
            }
            if (count >= countStop) {
                return;
            }
            setTimeout(timer, speedLimit);
        };

        timer();
    };

    return (
        <div className="connect_hero_container w-full">
            <div className="flex justify-center mt-0 lg:pt-12">
            <img alt={'alt-text'} className="absolute -right-[20%] benefit_side_bubble -z-[1]" src={Sidebubble}/>
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0 relative">
                <img alt={'alt-text'} className="absolute -right-[0%] -z-[1] bottom-[10%] w-[150px] hidden lg:inline-block" src={BenefitSpiral}/>
                    <div className="flex flex-row flex-wrap">
                        <div className="w-full lg:w-3/12 mb-6">
                        <img alt={'alt-text'} className="w-full max-w-[250px]" src={ isLight ? ApplogoLight : Applogo}/>
                        </div>
                        <div className="w-full lg:w-9/12 mb-20">
                            <p className={`benefit_titles ${isLight ? 'text-black' : 'text-night_greg'}`}>
                                Welcome to Night Owl, your trusted partner in revolutionizing how you manage table reservations and ticket sales.
                                Our state-of-the-art ticket reservation system is designed
                                to help night clubs like yours maximize efficiency, increase profits, and provide an exceptional experience for your patrons.
                            </p>
                            <p className={`benefit_titles_heads ${isLight ? 'text-black' : 'text-night_greg'}`}>Benefits of using Night Owl</p>
                            <div className="flex flex-wrap w-full" ref={wrapperRef}>
                                <div className="w-full mb-5 sm:w-4/12 lg:w-[28%] flex justify-center sm:justify-start flex-wrap">
                                    <img src={BenefitIncrease} alt="Benefit Increase" />
                                    <p className={`bold_benefit w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`} ref={beIncreaseRef}>15%</p>
                                    <p className={`bold_benefit_desc w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`}>Increase in reservation efficiency</p>
                                </div>
                                <div className="w-full sm:w-4/12 lg:w-[30%] mb-5 flex justify-center sm:justify-start flex-wrap">
                                    <img src={BenSatisfy} alt="Better Satisfaction" />
                                    <p className={`bold_benefit  w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`} ref={beSatisfyRef}>60%</p>
                                    <p className={`bold_benefit_desc  w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`}>Better user satisfaction</p>
                                </div>
                                <div className="w-full mb-5 sm:w-4/12 lg:w-[30%] flex justify-center sm:justify-start flex-wrap">
                                    <img src={Bengrowth} alt="Customer Growth" />
                                    <p className={`bold_benefit  w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`} ref={beGrowthRef}>25%</p>
                                    <p className={`bold_benefit_desc  w-full text-center sm:text-left ${isLight ? 'text-black' : 'text-night_greg'}`}>Customer growth rate</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
