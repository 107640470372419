import React from "react";
import { useTheme } from "context/useAppTheme";
import HintsWrapper from "components/widget/HintsWrapper";
import TableListSvg from "../../../assets/images/svg/TableList.svg"
import HintstablesSvg from '../../../assets/images/svg/HintstablesSvg.svg'
import CreateEvenetLight from '../../../assets/images/svg/CreateEvenetLight.svg'
import CreateEventDark from '../../../assets/images/svg/CreateEventDark.svg'
import PlanMenuDark from '../../../assets/images/svg/PlanMenuDark.svg'
import PlanMenuLight from '../../../assets/images/svg/PlanMenuLight.svg'
import ProcessReservationLight from '../../../assets/images/svg/ProcessReservationLight.svg'
import Processreservationdark from '../../../assets/images/svg/Processreservationdark.svg'

interface Props { }


const HowItWorks: React.FC<Props> = (props: Props) => {
    const { isLight } = useTheme()

    return (
        <div className="connect_hero_container w-full">

            <div className="flex justify-center mt-0 lg:pt-12">
                <div className="lg:w-10/12 md:w-11/12 flex max-w-[1500px] flex-wrap items-center px-3 lg:p-0">
                    <p className={`mb-4 text-[24px] w-full mt-16 ${ isLight ? 'text-black' : 'text-white'}`}>How it works</p>
                    <div className="my-3 w-full ">
                        <HintsWrapper title={"List your tables"} description={"Set availability and pricing with easy scaling for high traffic weekends."} 
                        src={ isLight ? HintstablesSvg : TableListSvg }
                        />
                    </div>
                    <div className="my-3 w-full">
                        <HintsWrapper reverseDisplay={true} title={"Create events"} description={"View when customers book a table and prepare for bottle orders prior to open."} 
                        src={ isLight ? CreateEvenetLight : CreateEventDark }/>
                    </div>
                    <div className="my-3 w-full">
                        <HintsWrapper title={"Plan your exquisite menu"} description={"Get access to our QR code tracking app to ensure confirmed guest entry, limiting pile ups at the door"} 
                        src={ isLight ? PlanMenuLight : PlanMenuDark } />
                    </div>
                    <div className="my-3 w-full">
                        <HintsWrapper reverseDisplay={true} title={"Process reservations"} description={"Transactions are made in app streamlining your payment and bettering customer experience."} 
                        src={ isLight ? ProcessReservationLight : Processreservationdark }/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
