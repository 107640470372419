import { useState } from 'react'
import { useTheme } from 'context/useAppTheme'
import { useNavigate } from 'react-router-dom'

interface Props { }

function ToggleSide(props: Props) {
    const { isLight } = useTheme()
    const [side, setSide] = useState('night_club')
    const navigate = useNavigate()


    return (
        <>
            {!isLight ? <div className={`side_switch text-white`}>
                <div className={`slide_bg ${isLight ? 'bg-night_primary_blue' : 'bg-white'}`} style={{ left: side === 'night_club' ? '0%' : '50%' }}></div>
                <span
                    onClick={() => { setSide('night_club'); navigate('/') }}
                    className={`night_club ${side === 'night_club' && 'text-black'}`}
                >
                    Night club
                </span>
                <span
                    onClick={() => { setSide('customer'); navigate('/customer')}}
                    className={`night_club ${side === 'customer' && 'text-black'}`}>
                    Customer
                </span>
            </div>
                :
                <div className={`side_switch text-black`}>
                    <div className={`slide_bg ${isLight ? 'bg-night_primary_blue' : 'bg-white'}`} style={{ left: side === 'night_club' ? '0%' : '50%' }}></div>
                    <span
                        onClick={() => { setSide('night_club'); navigate('/') }}
                        className={`night_club ${side === 'night_club' && 'text-white'}`}
                    >
                        Night club
                    </span>
                    <span
                        onClick={() => { setSide('customer'); navigate('/customer')}}
                        className={`night_club ${side === 'customer' && 'text-white'}`}>
                        Customer
                    </span>
                </div>
            }
        </>
    )
}

export default ToggleSide
