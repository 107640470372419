

interface Props {
    children: any;
    isOpen: boolean;
    outerClick?: boolean;
    setOpen: (val: boolean) => void
}

function Modal(props: Props) {
    const { children, isOpen, setOpen } = props

    const OutClick = (e: any) => {
        if (e.target === e.currentTarget) {
            // This will only run if the parent div is clicked, not its children
            setOpen(false);
        }
    }

    return (
        isOpen ? <div
            onClick={(e: any) => {
                OutClick(e)
            }}
            className="modalContainer">
            {children}
        </div> : <div></div>
    )
}

export default Modal
